import Link from 'next/link';
import { Balancer } from 'react-wrap-balancer';

import { Header } from '@/widgets/header';

import { Counter } from '@/features/counter';

import { QuoteCard } from '@/entities/quotes';

import { BentoGrid, BentoGridItem } from '@/shared/components/bento-grid';
import { Button } from '@/shared/components/ui/button';
import { WordRotate } from '@/shared/components/ui/word-rotate';
import { PageSeo } from '@/shared/lib/seo';

import { TechStackBeams } from './tech-stack-beams';

export const HomePage = () => {
  return (
    <>
      <PageSeo
        title="Home"
        description="Aims for developers who really care about code quality, architecture, security and all the best practices in frontend"
      />

      <div className="flex min-h-full flex-col py-4">
        <Header />

        <div className="my-16 flex min-h-[45vh] w-full">
          <div className="container flex">
            <div className="flex flex-1 items-center justify-center">
              <div className="grid h-full w-full grid-cols-1 gap-20 lg:grid-cols-2">
                <div className="flex flex-col items-center justify-center gap-4 lg:items-start">
                  <h1 className="flex flex-col text-center text-6xl lg:text-left">
                    <span className="flex flex-wrap justify-center gap-x-4 leading-normal lg:justify-start">
                      <WordRotate words={['Create', 'Test', 'Ship']} duration={3500} /> your app
                    </span>{' '}
                    <span>in days, not weeks</span>
                  </h1>

                  <Balancer as="span" className="block text-center text-lg text-muted-foreground md:text-left">
                    Aims for developers who really care about code quality, architecture, security and all the best
                    practices in frontend
                  </Balancer>

                  <div>
                    <Button asChild>
                      <Link href="https://github.com/devianllert/maehwa?tab=readme-ov-file#maehwa" target="_blank">
                        Get started
                      </Link>
                    </Button>
                  </div>
                </div>

                <div className="flex items-center justify-center">
                  <TechStackBeams />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-auto">
          <h2 className="mb-4 text-2xl">Examples</h2>

          <BentoGrid>
            <BentoGridItem
              title="Counter"
              description="Demonstrates basic state management with Effector"
              header={<Counter />}
              // icon={item.icon}
            />

            <BentoGridItem
              title="Quote of the day"
              description="Demonstrates data fetching with Effector"
              header={<QuoteCard />}
              className="md:col-span-2"
              // icon={item.icon}
            />
          </BentoGrid>
        </div>
      </div>
    </>
  );
};

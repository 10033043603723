import Link from 'next/link';

import { DisplayOnBrowserMount } from '@/shared/components/display-on-browser-mount';
import { MaehwaLogo } from '@/shared/components/maehwa-logo';
import { ThemeToggle } from '@/shared/components/theme-toggle';
import { Badge } from '@/shared/components/ui/badge';
import { Separator } from '@/shared/components/ui/separator';
import { APP_TITLE } from '@/shared/lib/seo';

export const Header = () => {
  return (
    <header className="w-full">
      <div className="container flex h-16 w-full items-center justify-between">
        <div className="flex items-center space-x-2">
          <Link href="/" className="flex items-center space-x-2">
            <MaehwaLogo className="text-2xl" fill="currentColor" />
            <span className="font-bold capitalize">{APP_TITLE}</span>
          </Link>

          <Badge variant="secondary">Alpha 0.1.0</Badge>
        </div>

        <div className="flex items-center space-x-2">
          {/* <Media at="sm">
            <Badge variant="secondary">Beta mobile</Badge>
          </Media>
          <Media greaterThan="sm">
            <Badge variant="secondary">Beta desktop</Badge>
          </Media> */}

          <DisplayOnBrowserMount>
            <ThemeToggle />
          </DisplayOnBrowserMount>
        </div>
      </div>

      <Separator />
    </header>
  );
};

import * as React from 'react';
import Image from 'next/image';

import { MaehwaLogo } from '@/shared/components/maehwa-logo';
import { AnimatedBeam } from '@/shared/components/ui/beam';
import { ShineBorder } from '@/shared/components/ui/shine-border';
import { staticPath } from '@/shared/config/$path';
import { cn } from '@/shared/lib/cn';

const Circle = React.forwardRef<HTMLDivElement, { className?: string; children?: React.ReactNode }>(
  ({ className, children }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'z-10 flex h-12 w-12 items-center justify-center rounded-full border-2 border-border bg-white p-2 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]',
          className,
        )}
      >
        {children}
      </div>
    );
  },
);

Circle.displayName = 'Circle';

export function TechStackBeams() {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const appRef = React.useRef<HTMLDivElement>(null);

  const reactRef = React.useRef<HTMLDivElement>(null);
  const nextRef = React.useRef<HTMLDivElement>(null);
  const effectorRef = React.useRef<HTMLDivElement>(null);
  const tailwindRef = React.useRef<HTMLDivElement>(null);
  const vitestRef = React.useRef<HTMLDivElement>(null);
  const fsdRef = React.useRef<HTMLDivElement>(null);

  return (
    <div className="relative flex h-full w-full items-center justify-center" ref={containerRef}>
      <div className="flex h-full w-full flex-row items-stretch justify-between gap-10">
        <div className="flex flex-col justify-center gap-4">
          <Circle ref={reactRef} className="ml-8">
            <Image width={24} height={24} src={staticPath.images.tools.react_svg} alt="React" />
          </Circle>
          <Circle ref={nextRef} className="ml-0">
            <Image width={24} height={24} src={staticPath.images.tools.nextjs_svg} alt="NextJS" />
          </Circle>
          <Circle ref={effectorRef} className="ml-8">
            <Image
              width={24}
              height={24}
              className="h-6 w-6"
              src={staticPath.images.tools.effector_png}
              alt="Effector"
            />
          </Circle>
        </div>

        <div className="flex flex-col justify-center">
          <ShineBorder
            borderRadius={99999}
            borderWidth={4}
            duration={30}
            className="p-1"
            color={['#53C1DE', '#FFA500', '#06B6D4', '#3193FF']}
          >
            <Circle ref={appRef} className="h-16 w-16 border-none text-black">
              <MaehwaLogo className="h-8 w-8" />
            </Circle>
          </ShineBorder>
        </div>

        <div className="flex flex-col items-end justify-center gap-4">
          <Circle ref={tailwindRef} className="mr-8">
            <Image width={24} height={14} src={staticPath.images.tools.tailwind_svg} alt="Tailwind" />
          </Circle>
          <Circle ref={fsdRef} className="mr-0">
            <Image width={24} height={24} src={staticPath.images.tools.fsd_png} alt="Feature-sliced design" />
          </Circle>
          <Circle ref={vitestRef} className="mr-8">
            <Image width={24} height={24} src={staticPath.images.tools.vitest_svg} alt="Vitest" />
          </Circle>
        </div>
      </div>

      <AnimatedBeam
        containerRef={containerRef}
        fromRef={reactRef}
        toRef={appRef}
        text={'React'}
        textAnchor="start"
        gradientStartColor="#53C1DE"
        gradientStopColor="#53C1DE"
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={nextRef}
        text={'NextJS'}
        textAnchor="start"
        toRef={appRef}
        gradientStartColor="white"
        gradientStopColor="gray"
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={effectorRef}
        text={'Effector'}
        textAnchor="start"
        toRef={appRef}
        gradientStartColor="yellow"
        gradientStopColor="#FFA500"
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={tailwindRef}
        text={'Tailwind'}
        textAnchor="end"
        toRef={appRef}
        gradientStartColor="#06B6D4"
        gradientStopColor="#06B6D4"
        reverse
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={vitestRef}
        text={'Vitest'}
        textAnchor="end"
        toRef={appRef}
        gradientStartColor="#FCC72B"
        gradientStopColor="#729B1B"
        reverse
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={fsdRef}
        text={'FSD'}
        textAnchor="end"
        toRef={appRef}
        gradientStartColor="#3193FF"
        gradientStopColor="#3193FF"
        reverse
      />
    </div>
  );
}

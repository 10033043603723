import { useUnit } from 'effector-react';

import { Button } from '@/shared/components/ui/button';

import { $count, decrement, increment } from './model';

export const Counter = () => {
  const { count, onDecrement, onIncrement } = useUnit({
    count: $count,
    onIncrement: increment,
    onDecrement: decrement,
  });

  return (
    <div className="flex items-center space-x-4">
      <Button size="icon" onClick={onDecrement} aria-label="Increment">
        -
      </Button>

      <span className="text-2xl">{count}</span>

      <Button size="icon" onClick={onIncrement} aria-label="Decrement">
        +
      </Button>
    </div>
  );
};

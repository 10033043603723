import { sample } from 'effector';

import { $$quotes } from '@/entities/quotes';

import { pagesPath } from '@/shared/config/$path';
import { declarePage } from '@/shared/lib/effector/page';

export const homePage = declarePage({
  pageType: pagesPath.$url().pathname,
});

sample({
  clock: homePage.open,
  target: $$quotes.randomQuoteQuery.refresh,
});

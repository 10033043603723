import { createEvent, createStore, sample, scopeBind } from 'effector';

import { scope } from '@/shared/lib/effector/scope';
import { notified, NotifyFxOptions } from '@/shared/lib/notification';

export const $count = createStore(0);

export const increment = createEvent();
export const decrement = createEvent();

sample({
  clock: increment,
  source: $count,
  fn: (count) => count + 1,
  target: $count,
});

sample({
  clock: decrement,
  source: $count,
  fn: (count) => count - 1,
  target: $count,
});

sample({
  clock: increment,
  source: $count,
  fn: (counter): NotifyFxOptions => ({
    message: 'Counter is increased',
    data: {
      description: `Now it's ${counter}`,
      action: {
        label: 'Undo',
        onClick: () => {
          const dec = scopeBind(decrement, { scope: scope! });
          dec();
        },
      },
    },
    type: 'info',
  }),
  target: notified,
});

import { useUnit } from 'effector-react';
import { RiRestartLine } from 'react-icons/ri';

import { Case, Switch } from '@/shared/components/switch';
import { Button } from '@/shared/components/ui/button';
import { Skeleton } from '@/shared/components/ui/skeleton';

import { $$quotes } from '.';

export const QuoteCard = () => {
  const { data, pending, error } = useUnit($$quotes.randomQuoteQuery);
  const updateQuote = useUnit($$quotes.updateRandomQuoteQuery);

  return (
    <div className="flex w-full max-w-xl space-x-4">
      <Switch>
        <Case condition={data && !pending}>
          <div className="w-full">
            <q className="text-md text-center">{data?.text}</q>

            {/** We use || operator because author can be an empty string */}
            <div className="text-xs">{data?.author || 'Unknown'}</div>
          </div>
        </Case>

        <Case condition={pending}>
          <div role="status" className="w-full space-y-2 py-1">
            <Skeleton className="h-4" />
            <Skeleton className="h-2 max-w-24" />
            <span className="sr-only">Loading...</span>
          </div>
        </Case>

        <Case condition={Boolean(error)}>
          <div className="w-full">Something went wrong</div>
        </Case>
      </Switch>

      <div>
        <Button size="icon" variant="ghost" onClick={updateQuote}>
          <span className="sr-only">Update quote</span>
          <RiRestartLine />
        </Button>
      </div>
    </div>
  );
};
